import React, { useEffect, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import BlogCard from "../components/BlogCard";
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlog, getCategoryBlog } from '../features/blogs/blogSlice';
import moment from 'moment';

const Blog = () => {
   const blogState = useSelector((state) => state?.blog?.blog);
   const categoryState = useSelector((state) => state?.blog?.category);

   const dispatch = useDispatch();
   const [selectedCategory, setSelectedCategory] = useState(null);

   useEffect(() => {
      dispatch(getAllBlog());
      dispatch(getCategoryBlog());
   }, [dispatch]);

   const handleCategoryClick = (category) => {
      setSelectedCategory(category === "Tout les blogs" ? null : category);
   };

   const filteredBlogs = selectedCategory
      ? blogState?.filter((blog) => blog.category === selectedCategory.title)
      : blogState;

   return (
      <>
         <Meta title={"Blogs"} />
         <BreadCrumb title="Conseils & Astuces" />
         <Container class1="blog-wrapper home-wrapper-2 py-5">
            <div className="row">
               <div className="col-12 col-md-3">
                  <div className='filter-card mb-3'>
                     <h3 className="filter-title">
                        Filtrer par catégorie
                     </h3>
                     <div>
                        <ul className='ps-0'>
                           <li onClick={() => handleCategoryClick("Tout les blogs")}>Tout les blogs</li>
                           {categoryState.isLoading && <p>Loading...</p>}
                           {categoryState.isSuccess && (
                              categoryState.data.map((category, index) => (
                                 <li key={index} onClick={() => handleCategoryClick(category)}>
                                    {category.title}
                                 </li>
                              ))
                           )}
                           {categoryState.isError && <p>Error: {categoryState.message}</p>}
                        </ul>
                     </div>
                  </div>
               </div>
               <div className="col-12 col-md-9">
                  <div className="row">
                     {filteredBlogs && filteredBlogs.map((item, index) => (
                        <div className="col-6 mb-3" key={index}>
                           <BlogCard
                              id={item?._id}
                              title={item?.title}
                              description={item?.description}
                              image={item?.images[0]?.url}
                              date={moment(item?.created_at).format('MMMM Do YYYY, h:mm a')}
                              category={item?.category}
                           />
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </Container>
      </>
   );
};

export default Blog;
