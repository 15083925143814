export const services = [
   {
      title: "Livraison Express",
      tagline: "Livraison Express",
      image: "images/service.png",
   },
   {
      title: "Expertise Beauté Personnalisée",
      tagline: "Conseils sur Mesure et Effiaces",
      image: "images/service2.png",
   },
   {
      title: "Support 24/7",
      tagline: "Faites vos achats avec un expert",
      image: "images/service-03.png",
   },
   {
      title: "Prix Abordables",
      tagline: "Des prix abordables pour tous les produits",
      image: "images/service-04.png",
   }
]
