import React, { useEffect } from 'react'
import BreadCrumb from '../components/BreadCrumb'
import Meta from '../components/Meta'
import Container from '../components/Container'
import { useDispatch, useSelector } from 'react-redux';
import { getUserProductWishlist } from '../features/user/userSlice';
import { addToWishlist, removeFromWishlist } from '../features/products/productSlice';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Wishlist = () => {
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(getUserProductWishlist())
   }, [])

   const wishlistState = useSelector((state) => state?.auth?.wishlist?.wishlist)

   const removeFromWishlistFunction = (id) => {
      dispatch(removeFromWishlist(id))
      setTimeout(() => {
         dispatch(getUserProductWishlist())
      }, 500)
   }

   return (
      <>
         <Meta title={"Wishlist"} />
         <BreadCrumb title="Liste de Souhaits" />
         <Container class1="wishlist-wrapper home-wrapper-2 py-5">
            <div className="row">
               {
                  wishlistState && wishlistState?.length === 0 && (
                     <div className='text-center fs-3'>Vous n'avez pas de produit dans votre liste de souhaits</div>
                  )
               }
               {
                  wishlistState && wishlistState?.map((item, index) => {
                     return (
                        <div className="col-3" key={index}>
                           <Card style={{ width: '18rem' }}>
                              <Card.Img variant="top" src={item?.images[0].url ? item?.images[0].url : "images/smartwatch.avif"} />
                              <Card.Body>
                                 <Card.Title>{item?.title}</Card.Title>
                                 <Card.Text>
                                    Prix: {item?.price} FCFA
                                 </Card.Text>
                                 <div className='d-flex justify-content-between button-whislist-layout'>
                                    <Link to={'/product/' + item?._id} className={`button d-flex justify-content-center see-product-button ${item?.quantity <= 0 ? 'out-of-stock' : ''}`}>
                                          Voir le produit
                                    </Link>
                                    <button className="btn text-danger" onClick={() => { removeFromWishlistFunction(item?._id) }}>
                                       Retirer
                                    </button>
                                    
                                 </div>
                              </Card.Body>
                           </Card>
                        </div>
                     )
                  })
               }
            </div>
         </Container>
      </>
   )
}

export default Wishlist
