import React, { useEffect, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCartProduct, getUserCart, addProdToCart, applyACoupon, updateCartQuantity } from '../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Cart = () => {
    const [couponCode, setCouponCode] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [quantities, setQuantities] = useState({}); // Local state for input quantities

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userCartState = useSelector(state => state.auth.cartProducts) || [];

    useEffect(() => {
        dispatch(getUserCart());
    }, []);

    useEffect(() => {
        let sum = 0;
        for (let index = 0; index < userCartState.products?.length; index++) {
            sum += Number(userCartState.products[index].count) * Number(userCartState.products[index].price);
        }
        setTotalAmount(sum);

        // Initialize local quantities
        const initialQuantities = userCartState.products?.reduce((acc, item) => {
            acc[item._id] = item.count;
            return acc;
        }, {});
        setQuantities(initialQuantities);

    }, [userCartState]);

    const applyCouponHandler = () => {
        dispatch(applyACoupon(couponCode))
            .then((response) => {
                if (response.meta.requestStatus === "fulfilled") {
                    setTotalAmount(response.payload.discount);
                }
            })
            .catch((error) => {
                console.error("Error applying coupon:", error);
            });
    };

    const deleteProductFromCart = (productId) => {
        dispatch(deleteCartProduct(productId))
            .then(() => dispatch(getUserCart()))
            .catch(error => console.error("Error deleting product from cart:", error));
    };

    // Updated updateProductQuantity function
    const updateProductQuantity = (productId, quantity) => {
        const newQuantity = parseInt(quantity, 10);
        if (isNaN(newQuantity) || newQuantity < 0) {
            toast.error("Quantité invalide: " + quantity);
            return;
        }

        // Dispatch the action to update the quantity in the cart
        dispatch(updateCartQuantity({ productId, newQuantity }))
            .then(() => dispatch(getUserCart())) // Fetch the updated cart
            .catch(error => console.error("Error updating product quantity:", error));
    };

    const goToCheckout = () => {
        for (let item of userCartState.products) {
            if (item.count > item.product.quantity) {
                toast.error("Quantité non disponible ! il ne reste que " + item.product.quantity + " produits en stock", { autoClose: 5000 });
                return;
            }
        }
        navigate('/checkout', { state: { totalAmount, couponCode } });
    };

    return (
        <>
            <Meta title={"Cart"} />
            <BreadCrumb title="Cart" />
            <Container class1="cart-wrapper home-wrapper-2 py-5">
                <div className="row">
                    <div className="col-12">
                        <div className="cart-header py-3 d-flex justify-content-between align-items-center">
                            <h4 className='cart-col-1'>Produit</h4>
                            <h4 className='cart-col-2'>Prix</h4>
                            <h4 className='cart-col-3'>Quantité</h4>
                            <h4 className='cart-col-4'>Totale</h4>
                        </div>
                        {
                            userCartState && userCartState.products && userCartState.products.map((item, index) => {
                                if (item && item.product) {
                                    return (
                                        <div key={index} className="cart-data py-3 mb-2 d-flex justify-content-between align-items-center">
                                            <div className='cart-col-1 gap-15 d-flex align-items-center'>
                                                <div className='w-25'>
                                                    <img src={item.product.images[0]?.url} className='img-fluid' alt="" />
                                                </div>
                                                <div className='w-75'>
                                                    <p>{item.product.title}</p>
                                                </div>
                                            </div>
                                            <div className='cart-col-2'>
                                                <h5 className="price">{item.price} FCFA</h5>
                                            </div>
                                            <div className='cart-col-3  d-flex align-items-center gap-15 quantity-col'>
                                                <div>
                                                    <input
                                                        className='form-control'
                                                        type="number"
                                                        min={0}
                                                        value={quantities[item._id] ?? item.count}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Allow the input to be empty and treat it as 0
                                                            setQuantities({ ...quantities, [item._id]: value === "" ? "" : parseInt(value, 10) });
                                                            // Only update the global state when a valid number is entered
                                                            if (value !== "" && !isNaN(value)) {
                                                                updateProductQuantity(item._id, parseInt(value, 10));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <AiFillDelete onClick={() => deleteProductFromCart(item.product._id)} className='text-danger' />
                                                </div>
                                            </div>
                                            <div className='cart-col-4'>
                                                <h5 className="price">{item.price * item.count} FCFA</h5>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                    <div className="col-12 py-2 mt-4">
                        <div className="d-flex justify-content-between align-items-baseline paiment-button-layout">
                            <Link to='/product' className='button'> Retourner aux produits </Link>
                            {
                                (totalAmount !== null || totalAmount !== 0) &&
                                <div className='d-flex flex-column align-items-end shipping-layout'>
                                    <h4>Total: {totalAmount ? totalAmount : 0} FCFA</h4>
                                    <p>Frais de livraison calculés lors du paiement</p>
                                    <div>
                                        <button onClick={goToCheckout} className='button border-0'>Paiement</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='coupon-code w-100 d-flex justify-content-center'>
                    <input
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder="Entrez votre code de coupon"
                    />
                    <button onClick={applyCouponHandler} className='button border-0'>Appliquer le coupon</button>
                </div>
            </Container>
        </>
    )
}

export default Cart;
