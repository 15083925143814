import axios from "axios";

export const base_url = "https://asianco-app-asvm.onrender.com/api/"
//export const base_url = "http://localhost:5001/api/";

const getTokenFromLocalStorage = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : null

export const config = {
   headers: {
      'Authorization': `Bearer ${getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""}`,
      'Accept': 'application/json'
   }
}

export const instance = axios.create({
   baseURL: base_url, 
   timeout: 10000, 
 });
 

 instance.interceptors.response.use(
   response => response,  // Continuer normalement pour les réponses réussies
   error => {
     if (error.response.status === 401) {
       // Déconnexion de l'utilisateur ici
       // Supprimer les informations d'authentification dans le local storage et rediriger vers la page de connexion
       localStorage.removeItem('customer');
       window.location.href = '/login';  // Redirection vers la page de connexion
     }
     return Promise.reject(error);
   }
 );
