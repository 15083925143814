import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import Container from "../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  createAnOrder,
  saveAddressUser,
  emptyCart,
} from "../features/user/userSlice";
import { getDeliveryZones } from "../features/deliveryzone/deliveryZoneSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

let shippingSchema = yup.object({
  firstName: yup.string().required("Le prénom est requis"),
  lastName: yup.string().required("Le nom de famille est requis"),
  phoneNumber: yup.string().required("Le numéro de téléphone est requis"),
  address: yup.string().required("Les détails de l'adresse sont requis"),
  zone: yup.string().required("La zone de livraison est requise"),
  comment: yup.string(),
});

const Checkout = () => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.auth?.cartProducts);
  const [totalAmount, setTotalAmount] = useState(null);
  const [shippingInfo, setShippingInfo] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({
    razorpayPaymentId: "",
    razorpayOrderId: "",
  });
  const [cartProductState, setCartProductState] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);

  const location = useLocation();

  
  const couponCode = location.state.couponCode;
  const isCouponApplied = couponCode ? true : false;

  const deliveryZones = useSelector(state => state.deliveryZone.deliveryZones);
  
  useEffect(() => {
    setTotalAmount(parseInt(location.state.totalAmount));
  }, [location.state.totalAmount]);

  useEffect(() => {
    dispatch(getDeliveryZones());
  }, [dispatch]);
  

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      zone: "",
      comment: "",
    },
    validationSchema: shippingSchema,
    onSubmit: (values) => {
      setShippingInfo(values);
      setTimeout(() => {
        checkOutHandler();
      }, 300);
    },
  });


  useEffect(() => {
    let items = [];
    for (let index = 0; index < cartState?.length; index++) {
      items.push({
        product: cartState[index].productId?._id,
        quantity: cartState[index].quantity,
        color: cartState[index].color._id,
        price: cartState[index].price,
      });
    }
    setCartProductState(items);
  }, []);

  const navigate = useNavigate();



  const checkOutHandler = async () => {
    try {
      const addressResponse = await dispatch(
        saveAddressUser({ address: shippingInfo.address })
      );
      if (addressResponse.meta.requestStatus === "fulfilled") {
        const orderResponse = await dispatch(createAnOrder({ COD: "true", couponApplied : isCouponApplied, shippingInfo: {
          firstName: formik.values.firstName, 
          lastName: formik.values.lastName,
          comment: formik.values.comment,
          phoneNumber: formik.values.phoneNumber,
          address: formik.values.address,
          zone: formik.values.zone,
          shippingCost: shippingCost,
        }}));
        if (orderResponse.meta.requestStatus === "fulfilled") {
          try {
            const emptyResponse = await dispatch(emptyCart());
          } catch (error) {
          }
          navigate("/product");
        } else {
          alert("Failed to create order");
        }
      } else {
        alert("Failed to save address");
      }
    } catch (error) {
      alert("Something Went Wrong");
    }
  };

  const handleZoneChange = (zone) => {
    const selectedZone = deliveryZones.find((z) => z.zone === zone);
    if (selectedZone) {
      setShippingCost(selectedZone.price); // Mettre à jour le tarif de livraison
    } else {
      setShippingCost(0); // Si aucune zone sélectionnée, mettre le tarif à 0
    }
  };

  return (
    <>
      <Container class1="checkout-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-7">
            <div className="checkout-left-data">
              <h3 className="website-name">Asian Co</h3>
              <nav
                style={{ "--bs-breadcrumb-divider": ">" }}
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link className="text-dark total-price" to="/cart">
                      Cart
                    </Link>
                  </li>
                  &nbsp; /
                  <li
                    className="breadcrumb-item total-price active"
                    aria-current="page"
                  >
                    Information
                  </li>
                  &nbsp; /
                  <li className="breadcrumb-item total-price active">
                    Expédition
                  </li>
                  &nbsp; /
                  <li
                    className="breadcrumb-item total-price active"
                    aria-current="page"
                  >
                    Paiement
                  </li>
                </ol>
              </nav>
              
              <div className="col-12 col-md-12 col-lg-5 checkout-layout-sm mb-4">
                <div className="border-bottom py-4 ">
                {cartState && cartState.products.map((product, index) => (
                  <div key={index} className="d-flex gap-10 mb-2 align-items-center">
                    <div className="w-75 d-flex gap-10">
                      <div className="w-25 position-relative">
                        <span
                          style={{ top: "-1px", right: "2px" }}
                          className="badge bg-secondary text-white rounded-circle p-2 position-absolute"
                        >
                          {product.count}
                        </span>
                        <img
                          className="img-fluid"
                          width={100}
                          height={100}
                          src={product.product.images[0]?.url}
                          alt="product"
                        />
                      </div>
                      <div>
                        <h5 className="total-price">
                          {product.product.title}
                        </h5>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="total">
                        {product.price * product.count} FCFA
                      </h5>
                    </div>
                  </div>
                ))}
                </div>
                <div className="border-bottom py-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="total">Total</p>
                    <p className="total-price">
                      {totalAmount ? totalAmount : 0} FCFA
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 total">Livraison</p>
                    <p className="mb-0 total-price">{shippingCost}  FCFA</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                  <h4 className="total">Total</h4>
                  <h5 className="total-price">
                  {" "}
                  {totalAmount ? totalAmount + (parseInt(shippingCost) || 0) : 0}
                   </h5>
                </div>
              </div>
              <h4 className="mb-3">Adresse de livraison</h4>
              <form
                onSubmit={formik.handleSubmit}
                action=""
                className="d-flex gap-15 flex-wrap justify-content-between"
              >
                <div className="flex-grow-1">
                  <input
                    type="text"
                    placeholder="Prénom"
                    className="form-control"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="error ms-2 my-1">
                    {formik.touched.firstName && formik.errors.firstName}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <input
                    type="text"
                    placeholder="Nom de famille"
                    className="form-control"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="error ms-2 my-1">
                    {formik.touched.lastName && formik.errors.lastName}
                  </div>
                </div>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Numéro de téléphone"
                    className="form-control"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="error ms-2 my-1">
                    {formik.touched.phoneNumber && formik.errors.phoneNumber}
                  </div>
                </div>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Adresse"
                    className="form-control"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="error ms-2 my-1">
                    {formik.touched.address && formik.errors.address}
                  </div>
                </div>
                <div className="flex-grow-1">
                <select
                  className="form-control"
                  name="zone"
                  value={formik.values.zone}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleZoneChange(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Sélectionnez une zone de livraison</option>
                  {deliveryZones.map(zone => (
                    <option key={zone._id} value={zone.zone}>{zone.zone}</option>
                  ))}
                </select>
                  <div className="error ms-2 my-1">
                    {formik.touched.zone && formik.errors.zonee}
                  </div>
                  <div className="w-100 mt-3">
                  <textarea
                    placeholder="Laissez un commentaire (optionnel)"
                    className="form-control"
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                </div>

                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center checkout-button-layout">
                    <Link to="/cart" className="text-dark">
                      <GoArrowLeft className="me-2" />
                      Retourner au panier
                    </Link>
                    <Link to="/product" className="button">
                      Retourner aux produits
                    </Link>
                    <button className="button btn" type="submit">
                      Valider votre commande
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-5 checkout-layout-md">
            <div className="border-bottom py-4">
            {cartState && cartState.products.map((product, index) => (
              <div key={index} className="d-flex gap-10 mb-2 align-items-center">
                <div className="w-75 d-flex gap-10">
                  <div className="w-25 position-relative">
                    <span
                      style={{ top: "-1px", right: "2px" }}
                      className="badge bg-secondary text-white rounded-circle p-2 position-absolute"
                    >
                      {product.count}
                    </span>
                    <img
                      className="img-fluid"
                      width={100}
                      height={100}
                      src={product.product.images[0]?.url}
                      alt="product"
                    />
                  </div>
                  <div>
                    <h5 className="total-price">
                      {product.product.title}
                    </h5>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="total">
                    {product.price * product.count} FCFA
                  </h5>
                </div>
              </div>
            ))}
            </div>
            <div className="border-bottom py-4">
              <div className="d-flex justify-content-between align-items-center">
                <p className="total">Totale</p>
                <p className="total-price">
                  {totalAmount ? totalAmount : 0} FCFA
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 total">Livraison</p>
                <p className="mb-0 total-price">{shippingCost}  FCFA</p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom py-4">
              <h4 className="total">Total</h4>
              <h5 className="total-price">
                {" "}
                {totalAmount ? totalAmount + (parseInt(shippingCost) || 0) : 0}

              </h5>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Checkout;
