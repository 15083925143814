import axios from 'axios'
import { base_url, config, instance } from '../../utils/axiosconfig'

const register = async (userData) => {
   const response = await axios.post(`${base_url}user/register`, userData, { withCredentials: true })
   if (response.data) {
      if (response.data) {
         localStorage.setItem('customer', JSON.stringify(response.data))
      }
      return response.data
   }
}

const login = async (userData) => {
   const response = await axios.post(`${base_url}user/login`, userData, { withCredentials: true })
   if (response.data) {
      if (response.data) {
         localStorage.setItem('customer', JSON.stringify(response.data))
      }
      return response.data
   }
}

const getUserWishlist = async () => {
   const response = await instance.get(`${base_url}user/wishlist`, config)
   if (response.data) {
      return response.data
   }
}

const addToCart = async (cartData) => {
   console.log('cartData$$$$$$$$$$$', cartData)
   try {
      const response = await instance.post(`${base_url}user/cart`, cartData, config)
      if (response.data) {
         return response.data
      }
   } catch (error) {
      console.error("Erreur lors de l'ajout au panier :", error);
      throw error; 
   }
}

const updateCartQuantity = async (cartData) => {
   try {
      const response = await instance.put(`${base_url}user/cart/update-quantity`, cartData, config)
      if (response.data) {
         return response.data
      }
   }
   catch (error) {
      console.error("Erreur lors de la mise à jour de la quantité du produit :", error);
      throw error;
   }
}

const getCart = async () => {
   const response = await instance.get(`${base_url}user/cart`, config)
   if (response.data) {
      return response.data
   }
}

const removeProductFromCart = async (cartItemId) => {
   const response = await instance.delete(`${base_url}user/cart/${cartItemId}`, config)
   if (response.data) {
      return response.data
   }
}

const removeCart = async () => { 
   const response = await instance.delete(`${base_url}user/empty-cart`, config)
   if (response.data) {
      return response.data
   }

}

const updateProductFromCart = async (cartDetail) => {
   const response = await instance.delete(`${base_url}user/update-product-cart/${cartDetail.cartItemId}/${cartDetail.quantity}`, config)
   if (response.data) {
      return response.data
   }
}

const createOrder = async (orderDetail) => {
   const response = await instance.post(`${base_url}user/cart/cash-order`, orderDetail, config)
   if (response.data) {
      return response.data
   }
}

const getUserOrders = async () => {
   const response = await instance.get(`${base_url}user/get-orders`, config)
   if (response.data) {
      return response.data
   }
}

const updateUser = async (data) => {
   const response = await instance.put(`${base_url}user/edit-user`, data, config)
   if (response.data) {
      return response.data
   }
}

const saveAddress = async (data) => {
   const response = await instance.put(`${base_url}user/save-address`, data, config)
   if (response.data) {
      return response.data
   }
}

const forgotPassToken = async (data) => {
   const response = await instance.post(`${base_url}user/forgot-password-token`, data)
   if (response.data) {
      return response.data
   }
}

const resetPass = async (data) => {
   const response = await instance.put(`${base_url}user/reset-password/${data.token}`, { password: data?.password })
   if (response.data) {
      return response.data
   }
}

const applyCoupon = async (coupon) => {
   const response = await instance.post(`${base_url}user/cart/applycoupon`, {coupon}, config)
   if (response.data) {
      return response.data
   }
}

const logout = async () => {
   try {
      const response = await axios.get(`${base_url}user/logout`, {
         withCredentials: true 
      });
      if (response.data) {
         return response.data;
      }
   } catch (error) {
      console.error("Error logging out:", error);
      throw error;
   }
}

export const authService = {  
   register,
   login,
   getUserWishlist,
   addToCart,
   getCart,
   removeProductFromCart,
   updateProductFromCart,
   createOrder,
   getUserOrders,
   updateUser,
   forgotPassToken,
   resetPass,
   saveAddress, 
   removeCart,
   applyCoupon,
   logout,
   updateCartQuantity
}