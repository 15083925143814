import React from 'react'
import { Link } from 'react-router-dom'
import { BsFacebook, BsInstagram, BsLinkedin, BsSnapchat, BsTiktok, BsYoutube } from 'react-icons/bs'
import newsletter from '../images/newsletter.png'

const Footer = () => {
   return <>
      <footer className='py-4'>
         <div className="container-xxl">
            <div className="row align-items-center">
               <div className="col-xs-12 col-sm-12 col-lg-5 ">
                  <div className="footer-top-data d-flex gap-30 align-items-center">
                     <img src={newsletter} alt="newsletter" />
                     <h2 className='mb-0 text-white'>Inscrivez-vous à la newsletter</h2>
                  </div>
               </div>
               <div className="col-xs-12 col-md-7 newsletter-input-sm">
                  <div className="input-group">
                     <input
                        type="text" className="form-control py-1"
                        placeholder="Votre Adresse E-mail"
                        aria-label="Votre Adresse E-mail" aria-describedby="basic-addon2"
                     />
                     <span className="input-group-text p-2" id="basic-addon2">
                        S'inscrire
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </footer>
      <footer className='py-4'>
         <div className="container-xxl">
            <div className="row">
               <div className="col-xs-12 col-md-4 mb-3 footer-sm">
                  <h4 className='text-white mb-4'>Contactez-nous</h4>
                  <div>
                     <address className='text-white fs-6'>
                        Asian Co
                     </address>
                     <a href="tel:+221 778884066" className='mt-3 d-block mb-1 text-white'>
                     +221 77 888 40 66
                     </a>
                     <a href="mailto:kcabsa@gmail.com" className='mt-2 d-block mb-0 text-white'>
                     asianco1123@gmail.com
                     </a>
                     <div className="social_icons d-flex align-items-center gap-30 mt-4">
                        <a className='text-white' href="https://www.facebook.com/profile.php?id=100063490541875&mibextid=eQY6cl">
                           <BsFacebook className='fs-4' />
                        </a>
                        <a className='text-white' href="https://www.instagram.com/asian_co_skincare?igsh=b28wcWozdnQ4dnVw&utm_source=qr">
                           <BsInstagram className='fs-4' />
                        </a>
                        <a className='text-white' href="https://www.tiktok.com/@kbeauty221?_t=8l8WX8LgofG&_r=1">
                           <BsTiktok className='fs-4' />
                        </a>
                        <a className='text-white' href="https://www.snapchat.com/add/k_beauty221?share_id=utlq1m5SR1-cXwknfFuAtw&locale=fr_US">
                           <BsSnapchat className='fs-4' />
                        </a>
                     </div>
                  </div>
               </div>
               <div className="col-xs-12 col-md-3 footer-sm">
                  <h4 className='text-white mb-4'>Information</h4>
                  <div className='footer-links d-flex flex-column'>
                     <Link to='/privacy-policy' className='text-white py-2 mb-1'>Politique de Confidentialité</Link>
                     <Link to='/refund-policy' className='text-white py-2 mb-1'>Politique de Remboursement</Link>
                     <Link to='/shipping-policy' className='text-white py-2 mb-1'>Politique de Livraison</Link>
                     <Link to='/term-conditions' className='text-white py-2 mb-1'>Termes & Conditions</Link>
                     <Link to='/blogs' className='text-white py-2 mb-1'>Blogs</Link>
                  </div>
               </div>
               <div className="col-xs-12 col-md-3 none-in-sm">
                  <h4 className='text-white mb-4'>Compte</h4>
                  <div className='footer-links d-flex flex-column'>
                     <Link className='text-white py-2 mb-1'>À Propos de Nous</Link>
                     <Link className='text-white py-2 mb-1'>Faq</Link>
                     <Link className='text-white py-2 mb-1'>Contact</Link>
                  </div>
               </div>
               <div className="col-xs-12 col-md-2 none-in-sm">
                  <h4 className='text-white mb-4'>Quick Links</h4>
                  <div className='footer-links d-flex flex-column'>
                     <Link to="/" className='text-white py-2 mb-1'>Accueil</Link>
                     <Link to="/product" className='text-white py-2 mb-1'>Nos Produits</Link>
                     <Link to='/my-orders' className='text-white py-2 mb-1'>Mes Commandes</Link>
                     <Link to='/blogs' className='text-white py-2 mb-1'>Conseils & Astuces</Link>
                  </div>
               </div>
            </div>
         </div>
      </footer>
      <footer className='py-4'>
         <div className="container-xxl">
            <div className="row">
               <div className="col-12">
                  <p className='text-center mb-0 text-white'>&copy; {new Date().getFullYear()}; Powered by <a href='https://kmansour.netlify.app/' style={{color: "white"}}>Infinity Spider Service</a></p>
               </div>
            </div>
         </div>
      </footer>
   </>
}

export default Footer