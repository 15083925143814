import React from 'react'
import { Link } from 'react-router-dom'

const BlogCard = (props) => {
   const { id, title, description, date, image, category } = props;
   return (
      <div className="blog-card">
         <div className="card-image">
            <img src={image ? image : "images/blog.jpeg"} className='img-fluid w-100' alt="blog" />
         </div>
         <div className="blog-content">
            <p className='date'>{date}</p>
            <h5 className="title">{title} <span className='category-blog'> - {category}</span> </h5>
            
            <p className="desc" dangerouslySetInnerHTML={{ __html: description?.substr(0, 100) + "..." }}></p>
            <Link to={'/blog/' + id} className='button'>Lire plus</Link>
         </div>
      </div>
   )
}

export default BlogCard