import axios from 'axios'
import { base_url, config } from '../../utils/axiosconfig'

const getDeliveryZones = async () => {
    const response = await axios.get(`${base_url}deliveryzone/`, config);
    
    return response.data;
    }
    

const deliveryZoneService = {
    getDeliveryZones,
    };

export { deliveryZoneService };