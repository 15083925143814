import React, { useEffect, useState } from 'react'
import BreadCrumb from '../components/BreadCrumb'
import ReactStars from "react-rating-stars-component";
import Meta from '../components/Meta'
import ReactImageZoom from 'react-image-zoom';
import { AiOutlineHeart } from 'react-icons/ai'
import Container from '../components/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addRating, getAProduct, getAllProduct } from '../features/products/productSlice';
import { toast } from 'react-toastify';
import { addProdToCart, getUserCart, emptyCart } from '../features/user/userSlice';

const SingleProduct = () => {
   const [quantity, setQuantity] = useState(1)
   const [alreadyAdded, setAlreadyAdded] = useState(false)
   const location = useLocation();
   const navigate = useNavigate()
   const getProductId = location.pathname.split('/')[2]
   const dispatch = useDispatch();
   const productState = useSelector(state => state.product?.singleproduct)
   const productsState = useSelector(state => state.product?.product)
   const cartState = useSelector(state => state.auth?.cartProducts) || [];


   const authState = useSelector(state => state.auth);

   const [imgSrc, setImgSrc] = useState("https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg");

   
   useEffect(() => {
      const img = new Image();
      img.src = productState?.images[0]?.url;
      img.onload = () => setImgSrc(productState?.images[0]?.url);
      img.onerror = () => setImgSrc("https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg");
   }, [productState]);

   useEffect(() => {
     if(false) {
        navigate('/login')
     }else{
      dispatch(getAProduct(getProductId))
      dispatch(getUserCart())
      dispatch(getAllProduct())
     }
   }, [])

  

   useEffect(() => {
      
      if (cartState) {
         for (let index = 0; index < cartState.length; index++) {
             if (getProductId === cartState[index]?.productId?._id) {
                 setAlreadyAdded(true);
                 break;
             }
         }
      }
     
   }, [cartState])

   const uploadCart = () => {
      if(authState.user === null) {
         navigate('/login')
      }

      if(quantity > productState.quantity) {
         toast.error("Quantité non disponible ! il ne reste que " + productState.quantity + " produits en stock", { autoClose: 5000 }) 
         return;
      }
         const cartItem = {
            _id: productState?._id,
            count: Number(quantity),
            color: "red",
            price: productState?.price
         };
         const cartData = { cart: [cartItem] };
         dispatch(addProdToCart(cartData))

         setTimeout(() => {
            dispatch(getUserCart())
         }, 200)
         

   }

   const buyProduct = () => {
      if (!productState) {
         return;
      }

      if(quantity > productState.quantity) {
         toast.error("Quantité non disponible ! il ne reste que " + productState.quantity + " produits en stock", { autoClose: 5000 }) 
         return;
      }


      else if(authState.user === null) {
         navigate('/login')
      }
      
      const cartItem = {
         _id: productState._id,
         count: Number(quantity),
         color: "red",
         price: productState.price
      };
      const cartData = { cart: [cartItem] };
      
      if (cartState === null) {
         return;
      }
      
      async function updateCartAndNavigate() {
         try {
           await dispatch(emptyCart());
           await dispatch(addProdToCart(cartData));
           await dispatch(getUserCart());
           navigate('/cart');
         } catch (error) {
           console.error(error);
         }
       }
       
       updateCartAndNavigate();
      
    }

   const props = {
      width: 594,
      height: 600,
      zoomWidth: 600,
      img: imgSrc,
   };

   const [orderedProduct, setorderedProduct] = useState(true);
   

   const closeModal = () => { };
   const [popularProduct, setPopularProduct] = useState([])
   useEffect(() => {
      let data = []
      for (let index = 0; index < productsState.length; index++) {
         const element = productsState[index];
         if (element.subcategory === 'popular') {
            data.push(element)
         }
         setPopularProduct(data)
      }
   }, [productState])

   const [star, setStar] = useState(null)
   const [comment, setComment] = useState(null)
   const addRatingToProduct = () => {
      if (star === null) {
         toast.error("Please add star rating")
         return false;
      } else if (comment === null) {
         toast.error("Please Write Review for Product")
         return false;
      }
      else if(authState.user === null) {
         navigate('/login')
      } else {
         dispatch(addRating({ star: star, comment: comment, prodId: getProductId }))
         setTimeout(() => {
            dispatch(getAProduct(getProductId))
         }, 100);
      }
      return false
   }

   return (
      <>
         <Meta title={"Product Names"} />
         <BreadCrumb title={productState?.title} />
         <Container class1="main-product-wrapper py-5 home-wrapper-2">
            <div className="row">
               <div className="col-12 col-md-6">
                  <div className="main-product-image">
                     <div>
                        <ReactImageZoom {...props} />
                     </div>
                  </div>
                  <div className="other-product-images d-flex flex-wrap gap-15">
                     {productState?.images.map((item, index) => {
                        return (
                           <div key={index}>
                              <img src={item?.url} className='img-fluid' alt="" />
                           </div>
                        )
                     })}
                  </div>
               </div>
               <div className="col-12 col-md-6">
                  <div className="main-product-details">
                     <div className='border-bottom'>
                        <h3 className='title'>
                           {productState?.title}
                        </h3>
                     </div>
                     <div className="border-bottom py-3">
                        <p className="price">{productState?.price} FCFA</p>
                        <div className="d-flex align-items-center gap-10">
                           <ReactStars
                              count={5}
                              size={24}
                              value={productState?.totalrating}
                              edit={false}
                              activeColor="#ffd700"
                           />
                              <p className='mb-0 t-review'>{productState?.ratings.length} avis</p>
                        </div>
                        <a className='review-btn' href="#review"> Donner un avis </a>
                     </div>
                     <div className="py-3 third-section">
                        
                        <div className='d-flex gap-10 align-items-center my-2'>
                           <h3 className='product-heading'>Category:</h3>
                           <p className='product-data'>{productState?.category}</p>
                        </div>
                        <div className='d-flex gap-10 align-items-center my-2'>
                           <h3 className='product-heading'>subcategory:</h3>
                           <p className='product-data'>{productState?.subcategory}</p>
                        </div>
                        <div className='d-flex gap-10 align-items-center my-2'>
                           <h3 className='product-heading'>Availability:</h3>
                           <p className='product-data'>En Stock</p>
                        </div>

                        <div className='d-flex gap-10 align-items-center my-2 desc'>
                           <div><h3 className='product-heading'>Description:</h3></div>
                           <div><p dangerouslySetInnerHTML={{ __html: productState?.description }} className='product-data pt-3'></p></div>
                        </div>
                        
                        
                        <div className='d-flex flex-row align-items-center gap-15 flex-row mt-2 mb-3 order-product-layout'>
                           <div className='quantity-layout'>
                              {
                                 alreadyAdded == false && <>
                                    <h3 className='product-heading'>Quantité:</h3>
                                    <div className=''>
                                       <input
                                          type="number"
                                          name=""
                                          min={1}
                                          max={10}
                                          className='form-control'
                                          style={{ width: "70px" }}
                                          id=""
                                          onChange={(e) => setQuantity(e.target.value)}
                                          value={quantity}
                                       />
                                    </div>
                                 </>
                              }
                           </div>
                           <div className={alreadyAdded ? "ms-0" : "ms-5" + ' d-flex align-items-center gap-30 ms-5 button-layout quantity-layout-2'}>
                              <button
                                 className='button border-0 submit-to-buy'
                                 type='submit'
                                 onClick={() => { alreadyAdded ? navigate('/cart') : uploadCart() }}
                              >
                                 {
                                    alreadyAdded ? "Aller au panier" : "Ajouter au panier"
                                 }
                              </button>
                              <button className='button signup'
                                 type='submit'
                                 onClick={() => { buyProduct() }}
                              >Acheter</button>
                           </div>
                        </div>
                        <div className='d-flex flex-column align-items-start gap-15'>
                          
                           <div>
                              <a href="">
                                 <AiOutlineHeart className='fs-5 me-2' /> ajouter au liste de souhaits</a><br/>
                           </div>
                           <div className='d-flex gap-10 align-items-center my-2 ingredient'>
                              <h3 className='product-heading'>Ingredients:</h3>
                              <p className='product-data'>{productState?.ingredients}</p>
                           </div>
                        </div>
                        <div className='d-flex gap-10 flex-column my-3'>
                              <h3 className='product-heading'>Livraison et retours:</h3>
                              <p>Livraison et retours disponibles sur toutes les commandes ! <br /> Nous expédions toutes les commandes dans les plus bref délais</p>
                        </div>
                        
                     </div>
                  </div>
               </div>
            </div>
         </Container>

         <Container id='review' class1="reviews-wrapper home-wrapper-2">
            <div className="row">
               <div className="col-12">
                  <h3>Avis</h3>
                  <div className="review-inner-wrapper">
                     <div className="review-head d-flex justify-content-between align-items-end">
                        <div>
                              <h4 className='mb-2'>Avis des clients</h4>
                           <div className='d-flex align-items-center gap-10'>
                              <ReactStars
                                 count={5}
                                 size={24}
                                 value={4}
                                 edit={false}
                                 activeColor="#ffd700"
                              />
                                <p className='mb-0'>Basé sur 2 avis</p>
                           </div>
                        </div>
                        {
                           orderedProduct && (
                              <div>
                                 <a className='text-dark text-decoration-underline' href=""> Ajouter votre avis</a>
                              </div>
                           )
                        }
                     </div>
                     <div className="review-form py-4">
                        <h4 className='mb-2'>Ajouter votre avis</h4>

                        <div>
                           <ReactStars
                              count={5}
                              size={24}
                              value={0}
                              edit={true}
                              activeColor="#ffd700"
                              onChange={(e) => {
                                 setStar(e)
                              }}
                           />
                        </div>
                        <div>
                           <textarea
                              name=""
                              id=""
                              className='w-100 form-control'
                              cols="30" rows="4"
                              placeholder='Comments'
                              onChange={(e) => {
                                 setComment(e.target.value)
                              }}
                           >
                           </textarea>
                        </div>
                        <div className='d-flex justify-content-end mt-3'>
                           <button onClick={addRatingToProduct} className="button border-0" type='button'>Publier</button>
                        </div>
                     </div>
                     <div className="reviews mt-4">
                        {
                           productState && productState?.ratings?.map((item, index) => {
                              return (
                                 <div className="review" key={index}>
                                    <div className="d-flex gap-10 align-items-center">
                                       <h6 className='mb-0'>User2309</h6>
                                       <ReactStars
                                          count={5}
                                          size={24}
                                          value={item?.star}
                                          edit={false}
                                          activeColor="#ffd700"
                                       />
                                    </div>
                                    <p className='mt-3'>{item?.comment}</p>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               </div>
            </div>
         </Container>
         <Container class1='popular-wrapper py-5 home-wrapper-2'>
            
         </Container>
      </>
   )
}

export default SingleProduct