import React, { useEffect, useState } from 'react';
import BreadCrumb from '../components/BreadCrumb';
import Meta from '../components/Meta';
import ProductCard from "../components/ProductCard";
import Container from '../components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProduct } from '../features/products/productSlice';

const OurStore = () => {
   const [grid] = useState(4);
   const productState = useSelector((state) => state?.product?.product);
   const [brands, setBrands] = useState([]);
   const [categories, setCategories] = useState([]);
   const [subcategories, setSubcategories] = useState([]);

   // Filter States
   const [brand, setBrand] = useState(null);
   const [subcategory, setSubcategory] = useState(null);
   const [category, setCategory] = useState(null);
   const [minPrice, setMinPrice] = useState(null);
   const [maxPrice, setMaxPrice] = useState(null);
   const [sort, setSort] = useState('title');
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPages, setTotalPages] = useState(1);
   const [itemsPerPage, setItemsPerPage] = useState(9); 

   useEffect(() => {
      let newBrands = [];
      let categories = [];
      let subcategories = [];
      for (let index = 0; index < productState.length; index++) {
         const element = productState[index];
         newBrands.push(element.brand);
         categories.push(element.category);
         subcategories.push(element.subcategory);
      }
      setBrands(newBrands);
      setCategories(categories);
      setSubcategories(subcategories);
   }, [productState]);

   useEffect(() => {
      const total = productState.length;
      const totalPages = Math.ceil(total / itemsPerPage);
      setTotalPages(totalPages);
   }, [productState, itemsPerPage, totalPages]);


   const dispatch = useDispatch();


   useEffect(() => {
      dispatch(getAllProduct({ sort, subcategory, brand, category, minPrice, maxPrice, page: currentPage, limit: itemsPerPage }))
         .then(response => {
            setTotalPages(response.totalPages);
         });
   }, [sort, subcategory, brand, category, minPrice, maxPrice, currentPage, itemsPerPage, dispatch]);

   


   const filterBySubcategory = (products, subcategory) => {
      if (subcategory) {
         return products.filter(product => product.subcategory === subcategory);
      }
      return products;
   };

   const filterByCategory = (products, category) => {
      if (category) {
         return products.filter(product => product.category === category);
      }
      return products;
   };

   const getPaginatedProducts = (products) => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      return products.slice(startIndex, startIndex + itemsPerPage);
   };

   const goToNextPage = () => {
      if (currentPage < totalPages) {
         setCurrentPage(prevPage => prevPage + 1);
      }
   };

   const goToPreviousPage = () => {
      if (currentPage > 1) {
         setCurrentPage(prevPage => prevPage - 1);
      }
   };

   return (
      <>
         <Meta title={"Our Store"} />
         <BreadCrumb title="Nos Produits" />
         <Container class1="store-wrapper home-wrapper-2 py-5">
            <div className="row">
               <div className="col-12 col-md-12 col-lg-3">
                  
                  <div className='filter-card mb-3'>
                     <h3 className="filter-title">
                        Filtrer par
                     </h3>
                     <div>
                        <h5 className="sub-title">Prix</h5>
                        <div className='d-flex align-items-center gap-10'>
                           <div className="form-floating">
                              <input
                                 type="number"
                                 className="form-control"
                                 id="floatingInput"
                                 placeholder="From"
                                 onChange={(e) => setMinPrice(e.target.value)}
                              />
                              <label htmlFor="floatingInput">De</label>
                           </div>
                           <div className="form-floating">
                              <input
                                 type="number"
                                 className="form-control"
                                 id="floatingInput1"
                                 placeholder="To"
                                 onChange={(e) => setMaxPrice(e.target.value)}
                              />
                              <label htmlFor="floatingInput1">À</label>
                           </div>
                        </div>
                     </div>
                     <div className='mt-4 mb-3'>
                        <h5 className="sub-title">Catégories</h5>
                        <div>
                           <div className="product-tags d-flex flex-wrap align-items-center gap-10">
                              {
                                 subcategories && [...new Set(subcategories)].map((item, index) => {
                                    return (
                                       <span key={index} onClick={() => setSubcategory(item)} className="text-capitalize badge bg-light text-secondary rounded-3 py-2 px-3" style={{ cursor: "pointer" }}>
                                          {item}
                                       </span>
                                    )
                                 })
                              }
                           </div>
                           {subcategory !== null && <button onClick={() => setSubcategory(null)} className="text-capitalize badge bg-light text-secondary rounded-3 py-2 px-3 border-0 mt-2">Voir tout</button>}
                        </div>
                     </div>
                     
                  </div>
                  <div className='filter-card mb-3'>
                     <h5 className="sub-title">Marques</h5>
                     <div>
                        <div className="product-tags d-flex flex-wrap align-items-center gap-10">
                           {
                              brand === null && brands && [...new Set(brands)].map((item, index) => {
                                 return (
                                    <span key={index} onClick={() => setBrand(item)} className="text-capitalize badge bg-light text-secondary rounded-3 py-2 px-3" style={{ cursor: "pointer" }}>
                                       {item}
                                    </span>
                                 )
                              })
                           }
                        </div>
                        {brand !== null && <button onClick={() => setBrand(null)} className="text-capitalize badge bg-light text-secondary rounded-3 py-2 px-3 border-0 mt-2">Voir tout</button>}
                     </div>
                  </div>
               </div>
               <div className="col-12 col-md-12 col-lg-9">
                  <div className="filter-sort-grid mb-4">
                     <div className="d-flex justify-content-between align-items-center filter-input-layout ">
                        <div className="d-flex align-items-center gap-10">
                           <p className="mb-0 d-block" style={{ "width": "100px" }}>Trier par:</p>
                           <select
                              name=""
                              defaultValue={"title"}
                              className='form-control form-select'
                              id=""
                              onChange={(e) => setSort(e.target.value)}
                           >
                                <option value="title">Par ordre alphabétique, A-Z</option>
                                <option value="-title">Par ordre alphabétique, Z-A</option>
                                <option value="price">Prix, du moins cher au plus cher</option>
                                <option value="-price">Prix, du plus cher au moins cher</option>
                                <option value="createdAt">Date, de la plus ancienne à la plus récente</option>
                                <option value="-createdAt">Date, de la plus récente à la plus ancienne</option>
                           </select>
                        </div>
                        <div className='d-flex align-items-center gap-10'>
                           <p className="totalproduct mb-0">{productState?.length} Produits au total</p>
                           
                        </div>
                     </div>
                  </div>
                   <div className="products-list pb-5">
                     <div className="d-flex gap-6 flex-wrap">
                        <ProductCard data={productState ? getPaginatedProducts(filterByCategory(filterBySubcategory(productState, subcategory), category)) : []} grid={grid} />
                      </div>
                  </div>
               </div>
               <div className="pagination-buttons w-100 d-flex flex-row justify-content-between">
                  <button onClick={goToPreviousPage} disabled={currentPage === 1} className='btn pagination-btn'> {"< "}Page précédente </button>
                  <span>Page {currentPage} sur {totalPages}</span>
                  <button onClick={goToNextPage} disabled={currentPage === totalPages } className='btn pagination-btn'>Page suivante{" >"}</button>
               </div>
            </div>
         </Container>
      </>
   )
}

export default OurStore;
