import React, { useEffect } from 'react';
import ReactStars from "react-rating-stars-component";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist } from '../features/products/productSlice';
import { Card } from 'react-bootstrap';
import wish from '../images/wish.svg';
import { toast } from 'react-toastify';
import { getUserProductWishlist } from '../features/user/userSlice';
import { useState } from 'react';
import { htmlToText } from 'html-to-text';


const ProductCard = (props) => {
   const { grid, data } = props;
   const dispatch = useDispatch();
   let location = useLocation();

   const user = useSelector(state => state?.auth?.user);

   const wishlist = useSelector(state => state?.auth?.wishlist?.wishlist);
   

   useEffect(() => {
      if (user) {
         dispatch(getUserProductWishlist());
      }
   }, [dispatch, user]);
   



   const addProdToWishlist = (id) => {
      
      if (!user) {
         toast.warning('Vous devez vous connecter pour ajouter un produit à votre liste de souhaits !');
         return;
      } else {
         const isProductInWishlist = wishlist.some(item => item._id === id);

         if (isProductInWishlist) {
            toast.warning('Ce produit est déjà dans votre liste de souhaits !');
         } else {
            dispatch(addToWishlist(id));
   
            toast.success('Le produit a été ajouté à votre liste de souhaits !', {
               position: "top-right",
               autoClose: 1000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
            });
            setTimeout(() => {
               dispatch(getUserProductWishlist())
            }, 500)
         }
      }

      
      
      
   };

   

   

   const truncateString = (str, num) => {
      const text = htmlToText(str, {
         wordwrap: false,
         ignoreHref: true,
         ignoreImage: true,
      });
      if (text.length <= num) {
         return text;
      }
      return text.slice(0, num) + '...';
   };

   return (
      <>
         {data && data?.map((item, index) => (
            <div key={index} className={"col-6 col-sm-4 col-md-4 mx-0 px-0 mb-4"}>
               <Card style={{ width: '18rem' }} className='card-product'>
                  <Card.Img 
                  variant="top" 
                  src={item?.images[0]?.url || "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg" } 
                  onError={(e) => {e.target.onerror = null; e.target.src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"}}
                  style={{ height: '300px', objectFit: 'cover' }} />
                  <Card.Body>
                     <div className="wishlist-icon position-absolute">
                        <button className='border-0 bg-transparent' onClick={(e) => addProdToWishlist(item?._id)}>
                           <img src={wish} alt="wishlist"/>
                        </button>
                     </div>
                     <div className="d-flex flex-row justify-content-between align-items-center header-body-card">
                        <Card.Title className="product-title">
                           {truncateString(item?.title, 18)}
                           {item?.isPromo && <span className="promo-tag">Promo</span>}
                        </Card.Title>
                        <ReactStars
                           count={5}
                           size={12}
                           value={item?.totalrating.toString()}
                           edit={false}
                           activeColor="#ffd700"
                        />
                     </div>
                     <Card.Text>
                        {item?.quantity <= 0 ? (
                           <div className='h6 card-price-text pt-2 text-danger'>En rupture de stock</div>
                        ) : item?.isPromo ? (
                           <div>
                                 <span className='h6 card-price-text pt-2 me-3'> {item?.price} FCFA</span>
                                 <span style={{ textDecoration: 'line-through' }} className='text-danger'>{item?.basePrice} FCFA</span>
                           </div>
                        ) : (
                           <div className='h6 card-price-text pt-2'>{item?.price} FCFA</div>
                        )}
                     </Card.Text>
                     <Card.Text className='desc' dangerouslySetInnerHTML={{ __html: truncateString(item?.description, 30) }}></Card.Text>
                     <Link to={'/product/' + item?._id} className={`button d-flex justify-content-center see-product-button ${item?.quantity <= 0 ? 'out-of-stock' : ''}`}>
                        <div className='d-block'>Voir le produit</div>
                     </Link>
                  </Card.Body>
               </Card>
            </div>
         ))}
      </>
   );
}

export default ProductCard;
