import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { deliveryZoneService } from './deliveryZoneService';

export const getDeliveryZones = createAsyncThunk('deliveryzone/get', async (thunkAPI) => {
    
    try {
        return await deliveryZoneService.getDeliveryZones()
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
}
)

const initialState = {
    deliveryZones: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: ''
}

export const deliveryZoneSlice = createSlice({
    name: 'deliveryzone',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDeliveryZones.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDeliveryZones.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = "Success";
                state.deliveryZones = action.payload;
            })
            .addCase(getDeliveryZones.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.message = action.payload.message;
            })
    }
})

export default deliveryZoneSlice.reducer