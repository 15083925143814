import React, { useEffect, useState } from 'react'
import Container from '../components/Container'
import BreadCrumb from '../components/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { getOrders } from '../features/user/userSlice'


const Orders = () => {
   const dispatch = useDispatch();
   const orderState = useSelector(state => state?.auth?.getOrderedProduct);
   const user = useSelector(state => state?.auth?.user);
   const [loading, setLoading] = useState(false);
   
   useEffect(() => {
      if (user && !orderState) {
         setLoading(true); 
         dispatch(getOrders());
               }
   }, [user, orderState]);

   useEffect(() => {
      if (!orderState) {
         const timer = setTimeout(() => {
            window.location.reload();
         }, 2000);
   
         return () => clearTimeout(timer);
      }
   }, [orderState]);

   useEffect(() => {
      if (orderState) {
         setLoading(false);
      }
   }, [orderState]);


   const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
   };

   return (
      <>
         <BreadCrumb title='Mes commandes' />
         
         {orderState && orderState?.length === 0 && (
            <Container class1="cart-wrapper home-wrapper-2 py-5">
                  
                     <div className='text-center fs-3'>Vous n'avez pas encore commandé de produit</div>
                 
            </Container>
            )
         }
        
        {loading &&  (
         <Container className="cart-wrapper home-wrapper-2 py-2">
            <div className='text-center fs-3'>Chargement des commandes...</div>
         </Container>
      )}



            
         

         {orderState && orderState.map((order, orderIndex) => (
         <Container class1="cart-wrapper home-wrapper-2 py-2">
            <div className="row p-3 px-5 order-cart" style={{ backgroundColor: '#FFFFFF' }}>
               <div className="col-12">
                  <div className='row'>
                     <div className="col-2 text-center">
                        <h5>Numéro</h5>
                     </div>
                     <div className="col-3 text-center">
                        <h5>Nom du produit</h5>
                     </div>
                     <div className="col-3 text-center">
                        <h5>Prix</h5>
                     </div>
                     <div className="col-2 text-center">
                        <h5>Quantité</h5>
                     </div>
                     <div className="col-2 text-center">
                        <h5></h5>
                     </div>
                  </div>
               </div>
               <div className="col-12 mt-3 orders-layout">
                 
                     <div key={orderIndex}>
                        {order.products.map((product, index) => (
                                <div style={{ backgroundColor: "rgba(208, 11, 145, 0.246)" }} className='row pt-3 my-3' key={index}>
                                  <div className="col-2 text-center">
                                    <p>{index + 1}</p>
                                  </div>
                                  <div className="col-3 text-center">
                                    <p>{product.product.title}</p>
                                  </div>
                                  <div className="col-3 text-center">
                                    <p>{product.product.price}</p>
                                  </div>
                                  <div className="col-2 text-center">
                                    <p>{product.count}</p>
                                  </div>
                                  
                                  <div className="col-2 text-center view-product">
                                    <p>Voir le produit {">"}</p>
                                  </div>
                                </div>
                              ))}
                        <div className='d-flex pt-4 info-order-layout'>
                           <div className="col-4">
                              <h6>Date de la commande: {formatDate(order.createdAt)}</h6>
                           </div>
                           <div className='col-8 text-end'>
                              <h3>Total : {order.paymentIntent.amount}</h3>
                           </div>
                        </div>
                     </div>
                  
               </div>
            </div>

        
         </Container>
         ))}
      </>
   )
}

export default Orders;
