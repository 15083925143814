import React, { useEffect, useState } from 'react'
import ReactStars from "react-rating-stars-component";
import { Link, useNavigate } from 'react-router-dom'
import Container from '../components/Container';
import { services } from '../utils/Data';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBlog } from '../features/blogs/blogSlice'
import { getAllProduct, addToWishlist } from '../features/products/productSlice';
import Carousel from 'react-bootstrap/Carousel';
import ProductCard from "../components/ProductCard";





const Home = () => {
   const blogState = useSelector((state) => state?.blog?.blog)
   const productState = useSelector((state) => state?.product?.product)
   const navigate = useNavigate();
   const cartState = useSelector(state => state.auth?.cartProducts) || [];
   
   const [sort, setSort] = useState('-createdAt');
   const [grid] = useState(4);
   const [itemsPerPage, setItemsPerPage] = useState(9);

   

   const dispatch = useDispatch()
   useEffect(() => {
      dispatch(getAllBlog())
      dispatch(getAllProduct({sort, page: 1, limit: itemsPerPage}))
   }, [sort, itemsPerPage, dispatch])

   const addProdToWishlist = (id) => {
      dispatch(addToWishlist(id));
   }

   const getPaginatedProducts = (products) => {
      const startIndex = 0;
      return products.slice(startIndex, startIndex + itemsPerPage);
   };

   return <>
    <Carousel interval={3000}>
      <Carousel.Item>
      <img src='images/asianco-product/banner-01.jpg' class="banner-image" />
        <Carousel.Caption>
          <h3>Découvrez la Beauté Venue d'Asie !</h3>
          <p>Explorez notre sélection exclusive de produits cosmétiques coréens et japonais pour révéler votre éclat naturel. Des innovations révolutionnaires et des ingrédients éprouvés par le temps vous attendent.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src='images/asianco-product/banner-02.jpg' class="banner-image"/>
        <Carousel.Caption>
          <h3>La Magie de la K-Beauty et J-Beauty !</h3>
          <p>Plongez dans l'univers envoûtant de la K-Beauty et de la J-Beauty avec nos produits soigneusement sélectionnés. Découvrez des rituels de soins ancestraux et des formules modernes pour une peau éclatante et saine.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src='images/asianco-product/banner-04.jpg' class="banner-image"/>
        <Carousel.Caption>
          <h3>Trouvez Votre Éclat avec Asian Co !</h3>
          <p>
          Asian Co vous invite à découvrir une expérience de beauté authentique, inspirée par la tradition et alimentée par l'innovation. Trouvez les secrets de beauté qui correspondent à votre style de vie et à votre peau.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      <Container class1='home-wrapper-1 py-5'>
      
      <div class="container">
         <div class="row">
         <div className="trust-element-container">
               <div className="trust-element-content text-center">
               <h3>Produits de qualité garantie</h3>
               <p>Nous sommes fiers de vous offrir une sélection de produits triés sur le volet, provenant de sources fiables et authentiques. Chez nous, la qualité est une priorité absolue. Chaque produit est soigneusement examiné pour garantir son authenticité et sa fiabilité. Vous pouvez faire vos achats en toute confiance, sachant que vous recevrez des produits de la plus haute qualité.</p>
               <p>Nous comprenons l'importance de la transparence et de la confiance lorsqu'il s'agit d'acheter en ligne. C'est pourquoi nous nous engageons à fournir des informations détaillées sur chaque produit, y compris son origine, ses matériaux et ses caractéristiques. Si vous avez des questions ou des préoccupations, n'hésitez pas à contacter notre équipe de support client dévouée. Nous sommes là pour vous aider et vous assurer une expérience d'achat agréable et sans souci.</p>
        
               </div>
            </div>
         <div className="col-12">
            <div className="section-title my-4">
               <h2>Nouveautés </h2>
            </div>

                  <div className="products-list pb-5">
                     <div className="d-flex gap-6 flex-wrap">
                        <ProductCard data={productState ? getPaginatedProducts(productState) : []} grid={grid} />
                      </div>
                  </div>
            
            </div> 
          </div> 
      </div>
      </Container>


      <Container class1='home-wrapper-2 py-5 services-section'>
         <div className="row">
            <div className="col-12">
               <div className="services d-flex align-items-center justify-content-between">
                  {
                     services?.map((i, j) => {
                        return (
                           <div className="d-flex align-items-center gap-15" key={j}>
                              <img src={i.image} alt="services" />
                              <div>
                                 <h6>{i.title}</h6>
                                 <p className="mb-0">{i.tagline}</p>
                              </div>
                           </div>
                        )
                     })
                  }
               </div>
            </div>
         </div>
      </Container>
   </>
}

export default Home