import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import wishlist from '../images/wishlist.svg';
import user from '../images/user.svg';
import cart from '../images/cart.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getAProduct } from '../features/products/productSlice';
import { getUserCart, logoutUser } from '../features/user/userSlice';
import { Navbar, Container, Nav, Form, Button, Dropdown } from 'react-bootstrap';

const Header = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state?.auth);
  const wishlistState = useSelector((state) => state?.auth?.wishlist?.wishlist);
  const cartState = useSelector((state) => state.auth.cartProducts);
  const [total, setTotal] = useState(null);
  const productState = useSelector((state) => state?.product?.product);
  const [productOpt, setProductOpt] = useState([]);
  const [paginate, setPaginate] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserCart());
  }, []);

  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum = sum + Number(cartState[index].quantity) * Number(cartState[index].price);
      setTotal(sum);
    }
  }, [cartState]);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < productState?.length; index++) {
      const element = productState[index];
      data.push({ id: index, prod: element?._id, name: element?.title });
    }
    setProductOpt(data);
  }, [productState]);


  const handleLogout = () => {
    dispatch(logoutUser());
    setTimeout(() => {
      navigate('/login');
    }
    , 400);
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" variant="" className='nav-bar-header'>
        <Container className='container m-0 p-0'>
            <div className='d-flex flex-row align-items-center w-100 justify-content-between header-container'>
              <div>
                <Navbar.Brand as={Link} to="/">
                  <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" class="head-logo"/>
                </Navbar.Brand>
              </div>
              <div className='w-100 pt-2 toggle-content'>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <div className='navbar-small-screen'>
                    <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">Accueil</Nav.Link>
                    <Nav.Link as={Link} to="/product">Nos Produits</Nav.Link>
                    <Nav.Link as={Link} to="/my-orders">Mes Commandes</Nav.Link>
                    <Nav.Link as={Link} to="/blogs">Conseils et Astuces</Nav.Link>
                    <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                    </Nav>
                  </div>
                  <div className='d-flex w-100 justify-content-between search-info-content'>
                    <div></div>
                    <div className='w-50 d-flex justify-content-center'>
                      <Form className="d-flex align-items-center w-100 justify-content-center">
                      <Typeahead
                          id="pagination-example"
                          onPaginate={() => console.log('Results paginated')}
                          onChange={(selected) => {
                            window.location.href = `/product/${selected[0]?.prod}`;
                            dispatch(getAProduct(selected[0]?.prod));
                          }}
                          options={productOpt}
                          paginate={paginate}
                          labelKey={"name"}
                          minLength={2}
                          placeholder="Rechercher un produit..."
                          className='search-input'
                      />
                      <Button className='search-button'><BsSearch /></Button>
                      </Form>
                    </div>
                    <div className='user-info-container'>
                      <Nav>
                      <div>
                      <Nav.Link as={Link} to='/wishlist'>
                          <img src={wishlist} alt="wishlist" width="20" />
                          <span className="badge card-count text-white">
                            {wishlistState?.length ? wishlistState?.length : 0}
                          </span>
                      </Nav.Link>
                      </div>
                      <div>
                      <Nav.Link as={Link} to='/cart'>
                          <img src={cart} alt="cart" width="20" />
                          <span className="badge card-count text-white">
                            {cartState?.products.length ? cartState?.products.length : 0}
                          </span>
                      </Nav.Link>
                      </div>
                      <Dropdown className='toggle-login'>
                          <Dropdown.Toggle id="dropdown-basic" className='toggle-button'>
                            <img src={user} alt="user" width="20" className='me-2'/>
                            {/* {authState?.user === null ? 'Login' : authState?.user?.firstname} */}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className='dropdown-user-info'>
                            <Dropdown.Item as={Link} to={authState?.user === null ? '/login' : '/my-profile'} className='d-flex justify-content-center'>
                              {authState?.user === null ? 'Login' : authState?.user?.firstname}
                            </Dropdown.Item>
                            <hr />
                            <Dropdown.Item onClick={handleLogout} className='d-flex justify-content-center'>Déconnexion</Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                      </Nav>
                    </div>
                  </div>
                </Navbar.Collapse>
              </div>
            </div>
        </Container>
        <div className="row w-100 d-flex justify-content-center align-items-center navbar-large-screen py-2">
          <div className="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 ">
            <Nav className="me-auto d-flex justify-content-center align-items-center">
               <Nav.Link as={Link} to="/" className='text-white mx-2'>Accueil</Nav.Link>
               <Nav.Link as={Link} to="/product" className='text-white mx-2'>Nos Produits</Nav.Link>
               <Nav.Link as={Link} to="/my-orders" className='text-white mx-2'>Mes Commandes</Nav.Link>
               <Nav.Link as={Link} to="/blogs" className='text-white mx-2'>Conseils et Astuces</Nav.Link>
               <Nav.Link as={Link} to="/contact" className='text-white mx-2'>Contact</Nav.Link>
               </Nav>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
